import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="container py-5" style={{ height: "100vh" }}>
      <h1 className="text-danger">HTTP Error 404 </h1>
      <p className="text-danger">
        The resource you are looking for does nott exist <br /> or it may has been removed, had its name changed,
        or is temporarily unavailable.
      </p>
      <p>Sorry, the page you requested was not found.</p>
      <Link to="/">Go to Home</Link>
    </div>
  );
}

export default PageNotFound;

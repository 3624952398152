import React, { memo } from "react";
import { ReactComponent as ManagementIcon } from "../../../images/Course-Icons/Management.svg";
import { ReactComponent as EngineeringIcon } from "../../../images/Course-Icons/Engineering.svg";
import { ReactComponent as MedicalIcon } from "../../../images/Course-Icons/Medical.svg";
import { ReactComponent as LawIcon } from "../../../images/Course-Icons/Law.svg";

function StudyGoalSection() {
  return (
    <div className="container py-5">
      <div className="row g-2 py-md-5 ">
        <h2 className="text-theme">Select Your Study Goal</h2>
        <p className="pt-1 pb-3 w-md-50">
          Empower your future, one goal at a time. Choose, achieve, succeed -
          select your study goal
        </p>
        <div className="col-lg-3 col-6">
          <div className="row shadow rounded pt-3 px-2 m-1">
            <div className="px-1 d-flex ">
              <div>
                <ManagementIcon
                  className="rounded p-2 me-md-2 me-1"
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#f1f1f1",
                    backgroundColor: "#212159",
                  }}
                />
              </div>
              <div>
                <p className="m-0 fs-md-4 fs-5 fw-bold">Management</p>
                {/* <span className="">6124 colleges</span> */}
              </div>
            </div>
            <div className="mt-2 px-1">
              <p className="pb-3 border-bottom border-secondary">PGDM/MBA</p>
              <p className="pb-3 border-bottom border-secondary">BBA/BCA</p>
              <p className="">PGDBL</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="row shadow rounded pt-3 px-2 m-1">
            <div className="px-1 d-flex ">
              <div>
                <EngineeringIcon
                  className="rounded p-2 me-md-2 me-1"
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#f1f1f1",
                    backgroundColor: "#212159",
                  }}
                />
              </div>
              <div>
                <p className="m-0 fs-md-4 fs-5 fw-bold">Engineering</p>
                {/* <span className="">6124 colleges</span> */}
              </div>
            </div>
            <div className="mt-2 px-1">
              <p className="pb-3 border-bottom border-secondary">M.Tech</p>
              <p className="pb-3 border-bottom border-secondary">BE/B.Tech</p>
              <p className="mb-">Agriculture</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="row shadow rounded pt-3 px-2 m-1">
            <div className="px-1 d-flex ">
              <div>
                <MedicalIcon
                  className="rounded p-2 me-md-2 me-1"
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#f1f1f1",
                    backgroundColor: "#212159",
                  }}
                />
              </div>
              <div>
                <p className="m-0 fs-md-4 fs-5 fw-bold">Medical</p>
                {/* <span className="">6124 colleges</span> */}
              </div>
            </div>
            <div className="mt-2 px-1">
              <p className="pb-3 border-bottom border-secondary">MMeD</p>
              <p className="pb-3 border-bottom border-secondary">MBBS/BDS</p>
              <p className="">Nursing</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <div className="row shadow rounded pt-3 px-2 m-1">
            <div className="px-1 d-flex ">
              <div>
                <LawIcon
                  className="rounded p-2 me-md-2 me-1"
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#f1f1f1",
                    backgroundColor: "#212159",
                  }}
                />
              </div>
              <div>
                <p className="m-0 fs-md-4 fs-5 fw-bold">Law</p>
                {/* <span className="">6124 colleges</span> */}
              </div>
            </div>
            <div className="mt-2 px-1">
              <p className="pb-3 border-bottom border-secondary">LL.M.</p>
              <p className="pb-3 border-bottom border-secondary">BA/LLB</p>
              <p className="">PGDBL</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(StudyGoalSection);

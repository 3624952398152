import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import AllCollegeFilterPage from "./pages/allCollegeFilterPage/AllCollegeFilterPage";
import CollegePage from "./pages/collegePage/CollegePage";
import CollegeInfo from "./pages/collegePage/components/CollegeInfo";
import CollegeCoursesFee from "./pages/collegePage/components/CollegeCourses&Fee";
import CollegePlacement from "./pages/collegePage/components/CollegePlacement";
import CollegeAdmission from "./pages/collegePage/components/CollegeAdmission";
import CollegeFaculty from "./pages/collegePage/components/CollegeFaculty";
import CollegeFacility from "./pages/collegePage/components/CollegeFacility";
import CollegeHostel from "./pages/collegePage/components/CollegeHostel";
import CollegeFAQs from "./pages/collegePage/components/CollegeFAQs";
import CollegeScholarships from "./pages/collegePage/components/CollegeScholarships";
import ExamPage from "./pages/examPage/ExamPage";
import Overview from "./pages/examPage/components/Overview";
import ExamDate from "./pages/examPage/components/ExamDate";
import Counseling from "./pages/examPage/components/Counseling";
import CollegeReviews from "./pages/collegePage/components/CollegeReviews";
import PageNotFound from "./pages/PageNotFound";
import ContactPage from "./pages/contactPage/ContactPage";
import CollegesBySingleFilter from "./pages/allCollegesBySingleFilter/CollegesBySingleFilter";
import Eligibility from "./pages/examPage/components/Eligibility";
import Cutoff from "./pages/examPage/components/Cutoff";
import Result from "./pages/examPage/components/Result";
import ExamPattern from "./pages/examPage/components/ExamPattern";
import AdmidCard from "./pages/examPage/components/AdmidCard";
import Feedback from "./pages/feedback/Feedback";

function App() {
  const collegepagePath = "management/college/:college_name/:institute_id";
  return (
    <>
      {/* <RouterProvider router={router} /> */}
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="colleges/:cityOrStream" element={<CollegesBySingleFilter />} /> */}
          <Route
            path="college/:college_name/:institute_id"
            element={<CollegePage />}
          >
            <Route path="about" element={<CollegeInfo />} />
            <Route path="coursesandfees" element={<CollegeCoursesFee />} />
            <Route path="placements" element={<CollegePlacement />} />
            <Route path="admissions" element={<CollegeAdmission />} />
            <Route path="faculty" element={<CollegeFaculty />} />
            <Route path="facility" element={<CollegeFacility />} />
            <Route path="hostel" element={<CollegeHostel />} />
            <Route path="review" element={<CollegeReviews />} />
            <Route path="scholarships" element={<CollegeScholarships />} />
            <Route path="faqs" element={<CollegeFAQs />} />
          </Route>

          <Route path="exam/:exam_name/:exam_id" element={<ExamPage />}>
            <Route path="overview" element={<Overview />} />
            <Route path="examdate" element={<ExamDate />} />
            <Route path="counseling" element={<Counseling />} />
            <Route path="cutoff" element={<Cutoff />} />
            <Route path="result" element={<Result />} />
            <Route path="pattern" element={<ExamPattern />} />
            <Route path="admit-card" element={<AdmidCard />} />
            <Route path="eligibility" element={<Eligibility />} />
            <Route path="predictor" element={<Eligibility />} />
          </Route>

          {/* colleges/acceptes-exam/CAT */}
          <Route path="all-college" element={<AllCollegeFilterPage />} />
          <Route path="colleges" element={<AllCollegeFilterPage />} />
          <Route path="colleges/:slug" element={<AllCollegeFilterPage />} />
          <Route
            path="colleges/:city-best-colleges"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path="colleges/:stream-colleges-in-india"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path="colleges/:course-college-in-india"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path="colleges/:stream/:location-colleges"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path="colleges/:stream/:collegesInLocation"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path="colleges/:stream/:specialization-colleges-in-india"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path="colleges/acceptes-exam/:exam"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path=":stream/:course/all-college"
            element={<AllCollegeFilterPage />}
          />
          <Route
            path=":course/colleges/:course-colleges-city"
            element={<AllCollegeFilterPage />}
          />
          <Route path=":city-colleges" element={<AllCollegeFilterPage />} />

          <Route path="/contact" element={<ContactPage />} />
          <Route path="/feedback" element={<Feedback />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;

import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MainDescription from "../../../components/mainDescription/MainDescription";

type faculty = {
  instituteFacultyId: number;
  facultyName: string;
  metaTag: string;
  metaDescription: string;
  metaTitle: string;
  experience: string;
  qualification: string;
  email: string;
  phone: string;
  department: string;
  description: string;
};

function CollegeFaculty() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [facultyData, setFacultyData] = useState<faculty[]>();

  const fetchFacultiesData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/institute-faculties/institute/${institute_id}`
      );
      setFacultyData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFacultiesData();
  }, []);

  return (
    <>      
      <div className="college_highlights py-3">
        <p className="title text-theme">Our Faculties </p>
        <table className="table table-hover">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Qualification</th>
              <th scope="col">Department</th>
            </tr>
          </thead>
          <tbody>
            {facultyData?.map((faculty: faculty) => {
              return (
                <tr key={faculty.instituteFacultyId}>
                  <td>{faculty.facultyName}</td>
                  <td>{faculty.qualification}</td>
                  <td>{faculty.department}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default memo(CollegeFaculty);

import "./Home.css";
import HeroSection from "./components/HeroSection";
import StudyGoalSection from "./components/StudyGoalSection";
import HomeFilterSection from "./components/HomeFilterSection";
import FeaturedColleges from "../../components/featuredColleges/FeaturedColleges";
import NewsLetter from "./components/NewsLetter";
import TopColleges from "../../components/topColleges/TopColleges";
import TopStudyPlaces from "../../components/topStudyPlaces/TopStudyPlaces";
import { useEffect } from "react";
import Carousel from "../../components/carousel/Carousel";
import LeadsGenPopup from "../../components/leadsGenPopup/LeadsGenPopup";

export default function Home() {
  useEffect(() => {
    if (localStorage.getItem("collegeInfo")) {
      localStorage.removeItem("collegeInfo");
    }
  }, []);

  return (
    <>
      <HeroSection />
      <LeadsGenPopup />
      {/* <Carousel /> */}
      <div className="bg-light-theme">
        <div className="container py-5">
          <div className="row py-md-4">
            <div className="col-md-6 pb-3 text-center">
              <img
                src={require("../../images/circle-croped.png")}
                alt=""
                width="80%"
              />
            </div>
            <div className="col-md-6 mt-lg-5 mt-0 pt-lg-5 pe-lg-5">
              <h1 className="pt-3 fw-bold text-theme">
                We help Students Grow <br /> Their Career
              </h1>
              <div className="pb-md-0 pb-lg-5 text-start text-theme">
                <p className="pb-4 border-bottom border-2 text-theme w-80">
                  Unlocking Potential, Fostering Futures: Empowering students to
                  flourish and shape their career journey. We're not just
                  mentors; we're career cultivators.
                </p>
                <div className="row pt-md-2 pt-0 justify-content-between">
                  <div className="col">
                    <h1 className="mb-0">50k</h1>
                    <p>Students</p>
                  </div>
                  <div className="col">
                    <h1 className="mb-0">2k+</h1>
                    <p>Courses</p>
                  </div>
                  <div className="col">
                    <h1 className="mb-0">130</h1>
                    <p>Colleges</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StudyGoalSection />

      <HomeFilterSection />
      <TopStudyPlaces />
      {/* <div className="container py-5 top_colleges_section">
        <TopColleges page={"home"} />
      </div> */}
      <FeaturedColleges />
      <NewsLetter />
    </>
  );
}

import React, { FormEvent, useEffect, useState } from "react";
import "./NewsLetter.css";
import { subscribe } from "diagnostics_channel";
import axios, { AxiosError } from "axios";

type userDetails = {
  newLetterId: number;
  email: string;
  phoneNumber: string;
  course: string;
};

function NewsLetter() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";

  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    course: "",
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    // console.log(formData + " " + baseURL);
    const url = 'http://223.239.159.251:8080';
    
    try {
      const response = await axios.post(
        `${baseURL}/newsletter-subscribe`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle successful response
      // console.log("Status:- " + response.data);
    } catch (error: any) {
      // Handle AxiosError
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(
            "Server responded with error:",
            axiosError.response.status
          );
          console.error("Error data:", axiosError.response.data);
        } else if (axiosError.request) {
          // The request was made but no response was received
          console.error("No response received from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up the request:", axiosError.message);
        }
      } else {
        // Handle other types of errors
        console.error("Non-Axios error:", error.message);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toString() });
  };

  return (
    <div className="news_letter_section bg-theme text-light">
      <div className="container">
        <h2 className="pt-md-5 pt-3">Subscribe To Our News Letter</h2>
        <p className="pt-2 pb-3">
          Get College Notificaitons, Exam Notifications and News Updates
        </p>
        <form onSubmit={handleSubmit}>
          <div className="row g-2 pb-md-5 pb-3">
            <div className="col-md-4 ">
              <div className="bg-light rounded-1 ">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Your Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="bg-light border rounded-1 ">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Your Number"
                  name="phoneNumber"
                  minLength={10}
                  // max={999999999}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="bg-light border rounded-1 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Course"
                  name="course"
                  value={formData.course}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="bg-danger rounded-1 p-2 text-center">
                <button type="submit" className="btn text-light fw-bold">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewsLetter;

import { memo } from 'react'

function ExamPattern() {
  return (
    <div>
      Patterns
    </div>
  )
}

export default memo(ExamPattern)

import { useEffect, useState } from "react";
import "./HomeFilterSection.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { ReactComponent as EngineeringIcon } from "../../../images/Course-Icons/Engineering.svg";

import { ReactComponent as DelhiImg } from "../../../images/Citys Icons/Delhi.svg";

type streams = {
  streamId: number;
  streamName: string;
  slug: null;
  streamIcon: null;
  noOfColleges: number;
  noOfCourses: number;
  noOfExams: number;
  isMenuShow: number;
  isShowFilter: number;
  metaTag: string;
  metaDescription: string;
  metaTitle: string;
};

function HomeFilterSection() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const [streamsData, setStreamsData] = useState<streams[]>();
  const [showFiltered, setShowFiltered] = useState("colleges");
  const handleFilterClick = (e: any) => {
    setShowFiltered(e.target.id);
  };

  const fetchAdmissionData = async () => {
    try {
      const response = await axios.get(`${baseURL}/streams`);
      setStreamsData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAdmissionData();
  }, []);
  // useEffect(() => {
  //   console.log(showFiltered);
  //   console.log(streamsData);
  // }, [showFiltered, streamsData]);

  return (
    <div className="bg-light-theme">
      <div className="container filter_section py-5">
        <div className="tabs py-0 py-md-4">
          <ul
            className="nav row text-center mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <li
              className={`nav-item col border border-1 shadow rounded-start ${
                showFiltered === "colleges" ? "bg-theme" : "bg-white"
              } `}
            >
              <a
                id="colleges"
                className={`nav-link ${
                  showFiltered === "colleges" ? "text-light" : "text-theme"
                }`}
                onClick={(e) => handleFilterClick(e)}
              >
                College
              </a>
            </li>
            <li
              className={`nav-item col border border-1 shadow ${
                showFiltered === "exams" ? "bg-theme" : "bg-white"
              }`}
            >
              <a
                id="exams"
                className={`nav-link ${
                  showFiltered === "exams" ? "text-light" : "text-theme"
                }`}
                onClick={(e) => handleFilterClick(e)}
              >
                Exam
              </a>
            </li>
            <li
              className={`nav-item col border border-1 shadow rounded-end ${
                showFiltered === "courses" ? "bg-theme" : "bg-white"
              }`}
            >
              <a
                id="courses"
                className={`nav-link ${
                  showFiltered === "courses" ? "text-light" : "text-theme"
                }`}
                onClick={(e) => handleFilterClick(e)}
              >
                Course
              </a>
            </li>
          </ul>
        </div>

        <div className="d-flex flex-wrap justify-content-center gap-4 pt-4 pb-5">
          {streamsData?.map((stream) => {
            if (stream.isShowFilter) {
              return (
                <div
                  key={stream.streamId}
                  className="filtered_column text-center"
                  style={{width: "180px"}}
                >
                  <div className="rounded py-4">
                    <Link to={`${showFiltered}/${stream.streamName}-colleges-in-india`}>
                      <EngineeringIcon
                        style={{
                          width: "70px",
                          fill: "#262163",
                          padding: "10px",
                        }}
                      />
                      <p className="fw-bold mb-0">{stream.streamName}</p>
                      <small>
                        {showFiltered === "colleges" &&
                          stream.noOfColleges + " Colleges"}
                        {showFiltered === "exams" &&
                          stream.noOfExams + " Exams"}
                        {showFiltered === "courses" &&
                          stream.noOfCourses + " Courses"}
                      </small>
                    </Link>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default HomeFilterSection;

import React, { memo } from "react";
import { Link } from "react-router-dom";
// import websiteLogo form "../"

function Footer() {
  return (
    <div className="footer shadow p-3bg-body ">
      <div className="container">
        <footer className="pt-5 px-2">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-6  pb-3">
              <h5>Top College</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to='colleges/top-mba-college-in-india' className="nav-link p-0 text-muted">
                    MBA
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="colleges/top-b.tech-college-in-india" className="nav-link p-0 text-muted">
                    B.Tech/B.E
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="colleges/top-mca-college-in-india" className="nav-link p-0 text-muted">
                    MCA
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="colleges/top-bca-college-in-india" className="nav-link p-0 text-muted">
                    BCA
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="colleges/top-m.tech-college-in-india" className="nav-link p-0 text-muted">
                    M.Tech
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="colleges/top-ma-college-in-india" className="nav-link p-0 text-muted">
                    MA
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="colleges/top-ba-college-in-india" className="nav-link p-0 text-muted">
                    BA
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-6  pb-3">
              <h5>Top Universities</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Engineering
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Management
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Medical
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Law
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Commerce
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Science
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Arts
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-6  pb-3">
              <h5>Top Links</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Home
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Features
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Pricing
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    FAQs
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    About
                  </Link>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-2 col-md-4 col-6  pb-3">
              <h5>Stydy Abroad</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Canada
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    USA
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    UK
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    UAE
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Australia
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Germany
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    Sweden
                  </Link>
                </li>
              </ul>
            </div> */}

            {/* <div className="col-lg-2 col-md-4 col-6  pb-3">
              <h5>Board Exams</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    CBSE Class 12
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    CBSE Class 12th Result
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    CBSE Class 12th Syllabus
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    CBSE Class 12th Exam Dates
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    CBSE Class 10
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    CBSE Class 10th Result
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to={''} className="nav-link p-0 text-muted">
                    CBSE Class 10th Syllabus
                  </Link>
                </li>
              </ul>
            </div> */}

            <div className="col pb-3">
              <form>
                <h5>Subscribe to our newsletter</h5>
                <p>Monthly digest of whats new and exciting from us.</p>
                <div className="d-flex w-100 gap-2">
                  <label htmlFor="newsletter1" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="newsletter1"
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                  />
                  <button className="btn btn-danger" type="button">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </footer>
      </div>
      <div className="border-top">
        <div className="container">
          <div className="d-flex justify-content-between pt-2 my-2">
            <Link to="/">
              <img
                className=""
                src={require("../../images/webLogo.png")}
                alt="Logo"
                height="40px"
              />
            </Link>
            <p className="pt-3" style={{fontSize:"12px"}}>© 2021 Company, Inc. All rights reserved.</p>
            {/* <ul className="pt-3 list-unstyled d-flex">
              <li className="ms-3">
              <a className="link-dark" href="#">
              </Link>
              </li>
              <li className="ms-3">
              <a className="link-dark" href="#">
              Instagram
              </Link>
              </li>
              <li className="ms-3">
                <a className="link-dark" href="#">
                  Whatsapp
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Footer);

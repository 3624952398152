import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, NavLink, Outlet } from "react-router-dom";

import axios from "axios";
import api from "../../Api/Api";
import { type } from "os";

type courseList = {
  courseMasterId: 1;
  courseName: string;
  slug: null;
  isMenuShow: null;
};
type courseSpecializationsList = {
  specializationId: number;
  specializationName: string;
  isMenuShow: string;
  slug: string;
};
type examList = {
  examMasterId: number;
  isMenuShow: string;
  examName: string;
  slug: string;
};
type location = {
  locationId: number;
  cityName: string;
  state: string;
};

type streams = {
  streamId: number;
  streamName: string;
  slug: null;
  isMenuShow: number;
  metaTag: string;
  metaDescription: string;
  metaTitle: string;
  courseList: courseList[];
  courseSpecializationsList: courseSpecializationsList[];
  examList: examList[];
  locations: location[];
};

function MenuBar() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  window.addEventListener("resize", function () {
    "use strict";
    window.location.reload();
  });
  document.addEventListener("DOMContentLoaded", function () {
    /////// Prevent closing from click inside dropdown
    document.querySelectorAll(".dropdown-menu").forEach(function (element) {
      element.addEventListener("click", function (e) {
        e.stopPropagation();
      });
    });
  });

  const [streams, setStreamsData] = useState<streams[]>();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/streams/stm-ctry`);
      console.log(response);
      
      setStreamsData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // console.log("api call");
    fetchData();
  }, []);

  return (
    <div className="">
      <nav className="main_navbar navbar navbar-expand-lg navbar-dark p-0">
        <button
          className="navbar-toggler my-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav pt-2">
            {streams &&
              streams.map((stream: streams) => {
                if (stream.isMenuShow) {
                  return (
                    <li
                      key={stream.streamId}
                      className="nav-item dropdown ktm-mega-menu"
                    >
                      <a
                        className="nav-link dropdown-toggle text-theme fw-500 px-3"
                        // href="#"
                        data-bs-toggle="dropdown"
                      >
                        {stream.streamName}
                      </a>
                      <div className="dropdown-menu mega-menu container shadow rounded-0 p-0 ">
                        <div className="row px-3 pb-3">
                          <div className="col-md-3">
                            <ul>
                              <li className="fw-bolder text-theme pt-3">
                                By Courses
                              </li>
                              {stream.courseList?.map((category) => {
                                if (category.isMenuShow) {
                                  return (
                                    <li key={category.courseMasterId}>
                                      <Link
                                        to={`colleges/${category.courseName.toLowerCase()}-college-in-india`}
                                      >
                                        {category.courseName}
                                      </Link>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                          <div className="col-md-3">
                            <ul>
                              <li className="fw-bolder text-theme pt-3">
                                By State
                              </li>
                              {stream.locations?.map((location: location) => {
                                return (
                                  <li key={location.locationId+" state"}>
                                    <Link
                                      to={`colleges/${stream.streamName.toLowerCase()}-colleges-in-${location.state.toLowerCase()}`}
                                    >
                                      {location.state}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="col-md-3">
                            <ul>
                              <li className="fw-bolder text-theme pt-3">
                                By City
                              </li>
                              {stream.locations?.map((location: location) => {
                                return (
                                  <li key={location.locationId+" city"}>
                                    <Link
                                      to={`colleges/${stream.streamName.toLowerCase()}-colleges-in-${location.cityName.toLowerCase()}`}
                                    >
                                      {location.cityName}
                                    </Link>
                                  </li>
                                );
                              })}
                              <li>
                                <Link
                                  to={`colleges/${stream.streamName.toLowerCase()}/${stream.streamName.toLowerCase()}-colleges-in-Noida`}
                                >
                                  Noida
                                </Link>
                              </li>
                            </ul>
                          </div>
                          {stream.courseSpecializationsList.length > 0 && (
                            <div className="col-md-3">
                              <ul>
                                <li className="fw-bolder text-theme pt-3">
                                  By Specialisation
                                </li>
                                {stream?.courseSpecializationsList?.map(
                                  (list) => {
                                    if (list.isMenuShow) {
                                      return (
                                        <li key={list.specializationId}>
                                          <Link
                                            to={`colleges/${stream.streamName.toLowerCase()}/${list.slug}`}
                                          >
                                            {list.specializationName}
                                          </Link>
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </ul>
                            </div>
                          )}
                          {stream.examList.length > 0 && (
                            <div className="col-md-3">
                              <ul>
                                <li className="fw-bolder text-theme pt-3">
                                  Exam
                                </li>
                                {stream?.examList?.map((list) => {
                                  if (list.isMenuShow) {
                                    return (
                                      <li key={list.examMasterId}>
                                        <Link
                                          to={`colleges/${list.slug.toLowerCase()}/${list.examMasterId}`}
                                        >
                                          {list.examName}
                                        </Link>
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                            </div>
                          )}
                          <div className="col-md-3">
                            <ul>
                              <li className="fw-bolder text-theme pt-3">
                                Popular Colleges
                              </li>
                              <li>IIT Kharagpur</li>
                              <li>IIT Mumbai</li>
                              <li>IIT Madraas</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
              })}
          </ul>
          {/* <NavLink
            className="pt-2 px-2"
            to="college/sangam-university-su-bhilwara/438/about"
          >
            College Page
          </NavLink>
          <NavLink
            className="pt-2 px-2"
            to="exam/cat/23/overview"
          >
            Exam Page
          </NavLink>
          <NavLink className="pt-2 px-2" to="/contact">
            Contact
          </NavLink> */}
        </div>
      </nav>
    </div>
  );
}

export default React.memo(MenuBar);
import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type faqs = {
  questions: string;
  answer: string;
  instituteProfileId: number;
  courseName: string;
  faqid: number;
  faqtype: string;
};

function CollegeFAQs() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [faqs, setFaqs] = useState<faqs[]>();
  const [errMess, setErrMess] = useState<string>();

  const fetchFAQs = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/instituteProfiles/institute-faq/${institute_id}`
      );
      console.log(response.data);

      setFaqs(response.data);
    } catch (error: any) {
      console.error("Error fetching faqs data:", error?.response);
      const mess = error?.response?.data?.message;
      setErrMess(mess);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  return (
    <>
      <div className="py-3">
        <p className="title text-theme">Frequestly Asked Questions: </p>
        <div className="accordion" id="accordionExample">
          {errMess?.length && <p>{errMess}</p>}
          {faqs &&
            faqs.map((f: faqs) => {
              return (
                <div key={f.faqid} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${f.faqid}`}
                      aria-expanded="false"
                      aria-controls={`${f.faqid}`}
                    >
                      {f.answer}
                    </button>
                  </h2>
                  <div
                    id={`${f.faqid}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{f.questions}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default memo(CollegeFAQs);

import React from "react";
import { ReactComponent as DelhiImg } from "../../images/Citys Icons/Delhi.svg";
import { ReactComponent as MumbaiImg } from "../../images/Citys Icons/Vector-1.svg";
import { ReactComponent as Hydrabad } from "../../images/Citys Icons/Vector-2.svg";
import { ReactComponent as BanglareImg } from "../../images/Citys Icons/Vector-3.svg";
import { ReactComponent as Chennai } from "../../images/Citys Icons/Chennai.svg";
import { ReactComponent as PuneImg } from "../../images/Citys Icons/Pune.svg";
import { ReactComponent as Indore } from "../../images/Citys Icons/Indore.svg";

import { Link } from "react-router-dom";
import "./TopStudyPlaces.css";

function TopStudyPlaces() {
  return (
    <div className="bg-light-theme pb-mb-5">
      <div className="container py-md-5">
        <h4 className="fs-2 text-theme text-center">Top Study Places</h4>
        {/* <p className="pt-1 pb-3">
          Loremas asjdfasdfa sifjas jfas fajsd jas fjasdlkf alskdjfoasjglkam
          <br></br> foajsflgja sglas lkgdjas ldkgjalksjdglkasdj gasjdgljaslg
        </p> */}
        <div className="top_cities d-flex flex-wrap justify-content-center gap-4 pt-4 pb-5">
          <div className="text-center">
            <Link to="colleges/ahmedabad-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <DelhiImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Ahmedabad</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>          
          <div className="text-center">
            <Link to="colleges/bangalore-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <BanglareImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Bangalore</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/dehradun-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <DelhiImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Dehradun</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/delhi-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <DelhiImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Delhi</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/gaziabad-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <DelhiImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Gaziabad</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>          
          <div className="text-center">
            <Link to="colleges/grater-noida-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <Hydrabad
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Greater Noida</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/gurugram-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <Hydrabad
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Gurugram</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/hydrabad-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <Hydrabad
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Hydrabad</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/indore-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <Indore
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Indore</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/jaipur-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <DelhiImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Jaipur</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/mumbai-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <MumbaiImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Mumbai</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/noida-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <DelhiImg
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Noida</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/chennai-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <Chennai
                  style={{ width: "100px", fill: "#212159", padding: "10px" }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Chennai</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
          <div className="text-center">
            <Link to="colleges/pune-best-colleges">
              <div className="bg-white rounded py-4 px-3">
                <PuneImg
                  style={{
                    width: "100px",
                    fill: "#212159",
                    padding: "10px",
                  }}
                />
                <p className="fw-bolder mb-0 pt-2 text-theme">Pune</p>
                {/* <small>3021 Colleges</small> */}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TopStudyPlaces);

import React from 'react'

function CollegeReviews() {
  return (
    <div>
      Reviews....
    </div>
  )
}

export default CollegeReviews

import React, { useEffect, useState } from "react";
import "./FeaturedColleges.css";
import { type } from "os";
import axios from "axios";
import { useParams, Link, NavLink } from "react-router-dom";

type institute = {
  instituteProfileId: number;
  name: string;
  instituteImage: null;
  slug: string;
  establishmentYear: string;
  ranking: number;
  aboutInstitute: string;
  logo: string;
  averagePackage: string;
  examExceptedList: [];
  location: {
    locationId: number;
    cityName: string;
    country: string;
    latitude: number;
    longitude: number;
    pincode: string;
    state: string;
  };
  tuitionFees: string;
};

function FeaturedColleges() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const [featuredIntitute, setFeaturedInstitute] = useState<institute[]>();

  const fetchFeaturedInstitute = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/instituteProfiles?_featured=true&_page=1&_size=8`
      );
      setFeaturedInstitute(response.data.content);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchFeaturedInstitute();
  }, []);

  return (
    <>
      <div className="container my-md-5 py-5 featured_colleges_section">
        <h4 className="fs-2 text-theme">Featured Colleges</h4>
        <p className="pt-1 pb-3 w-80">
          Explore standout institutions and exceptional educational
          opportunities with our meticulously curated selection of featured
          colleges. Your journey to success begins here
        </p>
        <div className="row g-3">
          {featuredIntitute?.map((data) => (
            <div
              key={data.instituteProfileId}
              className="featured_college col-lg-3 col-md-4 col-6"
            >
              <div className="featured-college-div border rounded-2">
                <img
                  className=""
                  src={require("../../images/featured-colleges/featured-college (1).png")}
                  alt="college-img"
                  width="100%"
                  // height="180px"
                />
                <Link
                  to={`/college/${data.name}-in-${data.location.cityName}/${data.instituteProfileId}/about`}
                >
                  <div
                    className="d-flex rounded-bottom bg-white px-2 align-items-center"
                    style={{ height: "60px" }}
                  >
                    <div className="college_img_div d-md-block d-none" style={{ width: "50px" }}>
                      <img
                        src={`http://192.168.1.2:942/cf/photo/institute/${data.logo}`}
                        alt="logo"
                        width="100%"
                        height="90%"
                      />
                    </div>
                    <div
                      className="px-2 college_name_div"
                    >
                      <p className="mb-0 college_name text-theme">
                        {data.name.length > 27
                          ? data.name.substring(0, 27) + "..."
                          : data.name}
                      </p>
                      <p className="mb-0 text-theme college_location">
                        {data.location.cityName} 
                        {', ' + data.location.state}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default FeaturedColleges;

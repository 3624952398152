import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainDescription from "../../../components/mainDescription/MainDescription";

type course = {
  instituteCourseId: number;
  durationInYears: number;
  avgPackage: number;
  minPackage: number;
  maxPackage: number;
  totalSeats: number;
  totalFees: number;
  eligibilityCriteria: string;
  instituteProfileId: number;
  universityName: string;
  courseSpecialization: string;
  certificateName: string;
  examAcceptedNameList: string[];
  courseName: string;
};

function CollegeCoursesFee() {
  // /institute-courses/by-institute/438
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [course, setCourses] = useState<course[]>();

  const fetchCourses = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/institute-courses/by-institute/${institute_id}`
      );
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const mainData =
    "Indian Institute of Technology, Madras has a Placement and Internship  Office that looks after and coordinates the complete process of  recruitment for students. The process of IIT Madras placement includes  registration of the companies with the institute. Following which the  companies fill in all the required fields of the Employer Registration  Form (ERF). After this, the companies go through the resumes of  interested students after which the companies conduct online or  offline tests, depending on their requirements in the Indian Institute  of Technology Madras placement. After this, slots are distributed to  students for an interview which is followed by the final interview  process. According to the IIT Madras NIRF data 2022, a total of 292  students from the UG (4-Year Programme) were placed in the year 2021  with a median IIT Madras placement package of Rs.15,00,000. A total of  21 students were placed from the PG Integrated (5-Year Programme)  2020-21 batch with a median salary of Rs. 9,00,000.";

  useEffect(() => {
    fetchCourses();
    // console.log("course api called")
  }, []);

  return (
    <>
      {/* <MainDescription heading={"main heading"} data={mainData} /> */}
      <div className="college_highlights py-3">
        {course && (
          <>
            <p className="title text-theme">Courses and Eligibility 2023</p>
            <table className="table table-hover">
              <thead className="table-secondary">
                <tr>
                  <th scope="col">Courses Offered</th>
                  <th scope="col">Specializaion</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Fees</th>
                  <th scope="col">Eligibility</th>
                </tr>
              </thead>
              <tbody>
                {course.map((course: course) => {
                  return (
                    <tr key={course.instituteCourseId}>
                      <td>{course.courseName}</td>
                      <td>{course.courseSpecialization}</td>
                      <td>{course.durationInYears} Yr.</td>
                      <td>{course.totalFees} L</td>
                      <td>{course.eligibilityCriteria}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>

      <div className="all_program py-3">
        <p className="title text-theme">All Programs</p>
        {/* <div className="input-group mb-3">
          <input
            type="text"
            className="form-control rounded-start-1 py-3"
            placeholder="Search by Courses, Specialization"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
          />
          <button
            className="btn btn-outline-secondary rounded-end-1"
            type="button"
            id="button-addon2"
            style={{ width: "15%" }}
          >
            Filter
          </button>
        </div> */}
        <div className="row g-2">
          {course?.map((course: course) => {
            return (
              <div key={course.instituteCourseId} className="col-6">
                <div className="border rounded-1">
                  <div className="pt-3 px-3 pb-2 bg-light">
                    <h5>
                      {course.courseName} - {course.courseSpecialization}
                    </h5>
                    <p className="mb-0">{course.durationInYears} Year</p>
                  </div>
                  <div className="px-3 row">
                    <div className="col">
                      <small>Min. Package</small>
                      <p>{course.examAcceptedNameList.join(" ")} LPA</p>
                    </div>
                    <div className="col">
                      <small>Avg. Package</small>
                      <p>{course.avgPackage} LPA</p>
                    </div>
                    <div className="col">
                      <small>Max. Package</small>
                      <p>{course.maxPackage} LPA</p>
                    </div>
                  </div>
                  <div className="px-3 pb-3">
                    <button className="rounded-1 bg-theme text-light px-2 pt-1 pb-2 me-1">
                      Brochure
                    </button>
                    <button className="rounded-1 bg-theme text-light px-2 pt-1 pb-2 me-1">
                      More
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default React.memo(CollegeCoursesFee);

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";

type searchData = {
  slugId: number;
  slug: string;
};

function SearchBar() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState<searchData[] | null>();

  const fetchSearchData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/slugs/search?keyword=${searchValue}`
      );
      setSearchData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = () => {
    setSearchValue('');
  }

  useEffect(() => {
    if (searchValue.length >= 3) {
      fetchSearchData();
    }
  }, [searchValue]);

  // useEffect(() => {
  //   console.log(searchData);
  // }, [searchData]);

  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <>
      <div className="bg-theme text-dark py-1 py-md-2 px-md-5">
        <div className="container">
          <div className=" input-group ">
            <input
              type="text"
              className="form-control border-0 rounded-start-1"
              placeholder="Search Colleges, Courses and Exams"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              // onFocus={onFocus}
              // onBlur={onBlur}
            />
            <button
              className="btn btn-outline-secondary bg-danger text-light border-0 rounded-end-1 fw-bold px-3"
              type="button"
              id="button-addon2"
              onClick={() => fetchSearchData()}
              // onFocus={onFocus}
              // onBlur={onBlur}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {searchValue.length >= 3 && searchData && (
        <div
          className="search_results container bg-white border position-absolute p-3 pb-0"
          style={{ right: "0", left: "0" }}
        >
          <ul
            className="overflow-auto border searchScrollbar search_scrollbar"
            style={{ height: "60vh" }}
          >
            {searchData?.map((data) => (
              <li key={data.slugId} className="p-2 border-bottom">
                <Link
                  to={`colleges/${data.slug.replaceAll(" ", "-")}`}
                  className="text-theme search_slugs"
                  onClick={handleClick}
                  style={{ textTransform: "capitalize" }}
                >
                  {data.slug}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default React.memo(SearchBar);

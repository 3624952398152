import React, { memo, useEffect, useState } from "react";
import MainDescription from "../../../components/mainDescription/MainDescription";
import { useLocation } from "react-router";

type ExamDetail = {
  examMasterId: number;
  examName: string;
  organisedBy: string;
  examIcon: string;
  slug: string;
  metaTag: string;
  metaTitle: string;
  metaDescription: string;
  examDetails: {
    examId: number;
    examDate: string;
    examLevel: string;
    description: string;
    slug: string;
    coursesList: string;
    applicationStartDate: string;
    applicationEndDate: string;
    onlineExamStartDate: string;
    onlineExamEndDate: string;
    offlineExamStartDate: string;
    admitCardDate: string;
    resultDate: string;
    isTreading: number;
    displayOrder: number;
    metaTag: string;
    metaTitle: string;
    metaDescription: string;
    counsellingEndtdate: string;
    offlineExamEndDate: string;
    counsellingStartdate: string;
  };
};

function Overview() {
  const [isLoading, setIsLoading] = useState(true);
  const currentUrl = useLocation();
  const [examDetail, setExamDetail] = useState<ExamDetail | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // This code will run whenever the URL changes.
        window.scrollTo(0, 0);

        // Simulate an API call or whatever asynchronous operation you need.
        // Replace this with your actual data fetching logic.
        await new Promise(resolve => setTimeout(resolve, 1000));

        const data = localStorage.getItem("examInfo");

        if (data) {
          // console.log("Hello from fetchData");
          setExamDetail(JSON.parse(data));
        }
      } catch (error) {
        console.error("Error fetching or parsing data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [currentUrl]);


  return (
    <>
      {isLoading && <p className="text-center p-5">Loading...</p>}
      {examDetail?.examDetails.description && (
        <MainDescription
          heading={`About`}
          data={examDetail?.examDetails.description}
        />
      )}
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-exam-date-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-exam-date"
            type="button"
            role="tab"
            aria-controls="nav-exam-date"
            aria-selected="true"
          >
            Exam Date
          </button>
          <button
            className="nav-link"
            id="nav-registration-process-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-registration-process"
            type="button"
            role="tab"
            aria-controls="nav-registration-process"
            aria-selected="false"
          >
            Registration Process
          </button>
          <button
            className="nav-link"
            id="nav-document-required-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-document-required"
            type="button"
            role="tab"
            aria-controls="nav-document-required"
            aria-selected="false"
          >
            Document Required
          </button>
          <button
            className="nav-link"
            id="nav-application-fee-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-application-fee"
            type="button"
            role="tab"
            aria-controls="nav-application-fee"
            aria-selected="false"
          >
            Application Fee
          </button>
          <button
            className="nav-link"
            id="nav-exam-city-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-exam-city"
            type="button"
            role="tab"
            aria-controls="nav-exam-city"
            aria-selected="false"
          >
            Exam City
          </button>
          <button
            className="nav-link"
            id="nav-eligibility-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-eligibility"
            type="button"
            role="tab"
            aria-controls="nav-eligibility"
            aria-selected="false"
          >
            Eligibility
          </button>
          <button
            className="nav-link"
            id="nav-syllabus-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-syllabus"
            type="button"
            role="tab"
            aria-controls="nav-syllabus"
            aria-selected="false"
          >
            Syllabus
          </button>
          <button
            className="nav-link"
            id="nav-pattern-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-pattern"
            type="button"
            role="tab"
            aria-controls="nav-pattern"
            aria-selected="false"
          >
            Pattern
          </button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-exam-date"
          role="tabpanel"
          aria-labelledby="nav-exam-date-tab"
        >
          <div className="about_college py-3">
            <p className="title text-theme">
              JEE Main 2024 Exam Dates Session 1{" "}
            </p>
            <p className="content" style={{ textAlign: "justify" }}>
              JEE Main 2024 Exam Dates for Sessions 1 and 2 have been released
              by NTA. The potential registration schedule for
              JEE Main 2024's January and April Sessions can be found below.
            </p>
          </div>
          <table className="table table-hover">
            <thead className="table-light">
              <tr>
                <th scope="col">Events</th>
                <th scope="col">Dates(Tentative)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JEE Main 2024 Notification</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Application Form Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Application Form Last Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Form Correction Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 City Intimation Slip Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Admit Card</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Exam Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Answer Key Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Result Date</td>
                <td>Dec 15, 2023</td>
              </tr>
            </tbody>
          </table>
          <div className="about_college py-3">
            <p className="title text-theme">
              JEE Main 2024 Exam Dates Session 2
            </p>
            <p className="content" style={{ textAlign: "justify" }}>
              JEE Main 2024 Exam Dates for Sessions 1 and 2 have been released
              by NTA. The potential registration schedule for
              JEE Main 2024's January and April Sessions can be found below.
            </p>
          </div>
          <table className="table table-hover">
            <thead className="table-light">
              <tr>
                <th scope="col">Events</th>
                <th scope="col">Dates(Tentative)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JEE Main 2024 Notification</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Application Form Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Application Form Last Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Form Correction Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 City Intimation Slip Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Admit Card</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Exam Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Answer Key Date</td>
                <td>Dec 15, 2023</td>
              </tr>
              <tr>
                <td>JEE Main 2024 Result Date</td>
                <td>Dec 15, 2023</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="tab-pane fade"
          id="nav-registration-process"
          role="tabpanel"
          aria-labelledby="nav-registration-process-tab"
        >
          <p className="mt-4 p-2 bg-light-theme">Registration Process</p>
          <div className="about_college py-3">
            <p className="title text-theme">
              JEE Main 2024 Exam Dates Session 2
            </p>
            <p className="content" style={{ textAlign: "justify" }}>
              JEE Main 2024 Exam Dates for Sessions 1 and 2 have been released
              by NTA. The potential registration schedule for JEE Main 2024's
              January and April Sessions can be found below.
            </p>
          </div>
          <div className="about_college py-3">
            <p className="title text-theme">
              How to Fill JEE Main 2024 Application Form?
            </p>
            <p className="content" style={{ textAlign: "justify" }}>
              Candidates can apply for JEE Main 2024 in three simple steps-
              Register Online, Fill Application Form and Pay the Application
              Fee. To fill JEE Main 2024 Application Form candidates can follow
              the below- mentioned steps-
            </p>
            <ul className="ps-3">
              <li>
                To apply for the JEE Main Exam 2024, go to the page "Apply for
                JEE Main Exam."
              </li>
              <li>
                Fill in the necessary information, such as exam-related
                information, medical, mailing, academic, and Guardian
                information, among other things.
              </li>
              <li>
                One by one, upload the scanned images according to the
                guidelines.
              </li>
              <li>
                Pay the application fee using a debit/credit card or an
                e-challan from Canara/Syndicate/HDFC/ICICI Bank in the payment
                gateway portion.
              </li>
              <li>
                NTA has made the annual family income as a mandatory field in
                JEE Main 2024 Application Form.
              </li>
              <li>
                Candidates have also been given an option to add their AADHAR
                Number in the application form.
              </li>
              <li>
                Once the candidate has successfully filled JEE Main 2024
                Application Form, the confirmation page is sent to the
                registered email of the candidate.
              </li>
            </ul>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-document-required"
          role="tabpanel"
          aria-labelledby="nav-document-required-tab"
        >
          ...
        </div>
      </div>
    </>
  );
}

export default memo(Overview);

import axios from "axios";
import { memo, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

type facility = {
  facilityId: number;
  wifi: number;
  library: number;
  canteen: number;
  cafeteria: number;
  placement: number;
  sap: number;
  internationalTour: number;
  erp: number;
  uniform: number;
  languages: number;
  industrialVisit: number;
  certificate: number;
  laptop: number;
  personalityDevelopment: number;
  scholarship: number;
  hostel: number;
  conveyance: number;
  loans: number;
  internship: number;
  scienceLab: number;
  computerLab: number;
  sports: number;
  auditorium: number;
  gym: number;
  amphitheatre: number;
  medical: number;
  stationery: number;
  furnished: number;
  food: number;
  tv: number;
  saloon: number;
  parking: number;
  girlsHostel: number;
  boysHostel: number;
  antiRagging: number;
  recordingStudio: number;
  swimmingPool: number;
  foodLab: number;
  eclass: number;
};

function CollegeFacility() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [facilityData, setFacilitiesData] = useState<facility>();

  const fetchFacultiesData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/instituteProfiles/institute-facilities/${institute_id}`
      );
      setFacilitiesData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFacultiesData();
  }, []);

  return (
    <>
      <div className="py-3">
        <p className="title text-theme">Facilities </p>
        <div className="row g-2" style={{fontSize: "12px"}}>
          {facilityData?.amphitheatre === 1 && (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/amphitheatre.png")}
                  alt="amphitheatre"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Amphitheatre</p>
              </div>
            </div>
          )}
          {facilityData?.antiRagging === 1 ? (
            <div className="col-2 d-none">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/anti_ragging.png")}
                  alt="antiRagging"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Anti Ragging</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.auditorium === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/auditorium.png")}
                  alt="auditorium"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Auditorium</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.boysHostel === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/boys_hostel.png")}
                  alt="boysHostel"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Boys Hostel</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.cafeteria === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/cafeteria.png")}
                  alt="cafeteria"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Cafeteria</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.canteen === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/canteen.png")}
                  alt="canteen"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Canteen</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.certificate === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/certificate.png")}
                  alt="certificate"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Certificate</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.computerLab === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/computerlab.png")}
                  alt="computerLab"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Computer Lab</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.conveyance === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/conveyance.png")}
                  alt="conveyance"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Conveyance</p>
              </div>
            </div>
          ) : (
            ""
          )}

          {facilityData?.eclass === 1  ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/e_class.png")}
                  alt="eclass"
                  height={50}
                />
                <p className="text-center mb-0 py-2">E-class</p>
              </div>
            </div>
          ) : (
            ""
          )}

          {facilityData?.erp === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/erp.png")}
                  alt="erp"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Erp</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.food === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/food.png")}
                  alt="food"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Food</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.foodLab === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/food_lab.png")}
                  alt="foodLab"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Food Lab</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.furnished === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/furnished.png")}
                  alt="furnished"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Furnished</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.girlsHostel === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/girls_hostel.png")}
                  alt="girlsHostel"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Girls Hostel</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.gym === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/gym.png")}
                  alt="gym"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Gym</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.hostel === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/hostel.png")}
                  alt="hostel"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Hostel</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.industrialVisit === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/industrial_visit.png")}
                  alt="industrialVisit"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Industrial Visit</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.internationalTour === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/international_tour.png")}
                  alt="internationalTour"
                  height={50}
                />
                <p className="text-center mb-0 py-2">International Tour</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.internship === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/internship.png")}
                  alt="internship"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Internship</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.languages === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/languages.png")}
                  alt="languages"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Languages</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.laptop === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/laptop.png")}
                  alt="laptop"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Laptop</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.library === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/library.png")}
                  alt="library"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Library</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.loans === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/loan.png")}
                  alt="loans"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Loans</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.medical === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/medical.png")}
                  alt="medical"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Medical</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.parking === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/parking.png")}
                  alt="parking"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Parking</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.personalityDevelopment === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/personality_development.png")}
                  alt="personalityDevelopment"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Personality Development</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.recordingStudio === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/placement.png")}
                  alt="recordingStudio"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Recording Studio</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.saloon === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/saloon.png")}
                  alt="saloon"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Saloon</p>
              </div>
            </div>
          ) : (
            ""
          )}

          {facilityData?.sap === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/sap.png")}
                  alt="sap"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Sap</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.scholarship === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/scholarship.png")}
                  alt="scholarship"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Scholarship</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.scienceLab === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/science_lab.png")}
                  alt="scienceLab"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Science Lab</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.sports === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/sports.png")}
                  alt="sports"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Sports</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.stationery === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/stationery.png")}
                  alt="stationery"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Stationery</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.swimmingPool === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/swimming.png")}
                  alt="swimmingPool"
                  height={50}
                />
                <p className="text-center mb-0 py-2">SwimmingPool</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.tv === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/tv.png")}
                  alt="tv"
                  height={50}
                />
                <p className="text-center mb-0 py-2">TV</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.uniform === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/uniform.png")}
                  alt="uniform"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Uniform</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {facilityData?.wifi === 1 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Facilities/wifi.png")}
                  alt="wifi"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Wifi</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default memo(CollegeFacility);

import React, { useEffect, useState } from "react";
import "./ExamPage.css";
import { Outlet, useLocation, NavLink, useParams, Link } from "react-router-dom";
import FeaturedColleges from "../../components/featuredColleges/FeaturedColleges";
import axios from "axios";

import collegeImg from "../../images/college-main-img.png";
import LeadsGenPopup from "../../components/leadsGenPopup/LeadsGenPopup";

type examDetail = {
  examMasterId: number;
  examName: string;
  organisedBy: string;
  examIcon: string;
  slug: string;
  metaTag: string;
  metaTitle: string;
  metaDescription: string;
  examDetails: {
    examId: number;
    examDate: string;
    examLevel: string;
    description: string;
    slug: string;
    coursesList: string;
    applicationStartDate: string;
    applicationEndDate: string;
    onlineExamStartDate: string;
    onlineExamEndDate: string;
    offlineExamStartDate: string;
    admitCardDate: string;
    resultDate: string;
    isTreading: number;
    displayOrder: number;
    metaTag: string;
    metaTitle: string;
    metaDescription: string;
    counsellingEndtdate: string;
    offlineExamEndDate: string;
    counsellingStartdate: string;
  };
};

function ExamPage() {
  const currentUrl = useLocation();

  useEffect(() => {
    // This code will run whenever the URL changes.
    // console.log("exam page :", currentUrl.pathname);
    fetchExamDetails();
  }, [currentUrl]);

  // localStorage.removeItem("examInfo");
  const { exam_id } = useParams();
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const [examDetail, setExamDetail] = useState<examDetail>();

  const fetchExamDetails = async () => {
    if (localStorage.getItem("examInfo")) {
      localStorage.removeItem("examInfo");
    }
    try {
      const response = await axios.get(`${baseURL}/exams/${exam_id}`);
      setExamDetail(response.data);
      localStorage.setItem("examInfo", JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("examInfo", JSON.stringify(examDetail));
  }, [examDetail]);

  return (
    <>
      <LeadsGenPopup />
      <div className="container exam_page_header border-0 my-3">
        <div className="bg-theme border-0">
          <div className="row border-0">
            <div className="col-12" style={{ height: "200px" }}>
              <img src={collegeImg} alt="College Image" width="100%" height="100%" />
            </div>
            <div className="col-2 exam_logo_div text-center pt-4 px-3">
              <img
                className="bg-light p-2"
                src={`http://192.168.1.2:942/cf/photo/institute/${examDetail?.examIcon}`}
                alt="Exam Logo"
                style={{
                  marginTop: "-50px",
                  maxWidth: "100%",
                  maxHeight: "70px",
                }}
              />
            </div>
            <div className="col-lg-7 col-md-6 col-10 text-light p-3">
              <h1 className="exam_full_name pt-1 mb-2">
                National Eligibility cum Entrance Test {examDetail && (examDetail.examName)}
              </h1>
            </div>
            <div className="col-lg-2 col-md-3 col-12 p-3">
              <button
                className="bg-danger text-light m-2 pt-1 pb-2 px-3 border-0 rounded-1"
                style={{ width: "120px" }}
              >
                <Link to="#" className="text-light">Exam Alert</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container exam_page_body my-3">
        {/* <div className="row"> */}
          {/* <div className="col"> */}
            <nav className="nav border rounded mb-3">
              <NavLink className="nav-link" to="overview">
                Overview
              </NavLink>
              <NavLink className="nav-link" to="examdate">
                Exam Date
              </NavLink>
              <NavLink className="nav-link" to="counseling">
                Counseling
              </NavLink>
              <NavLink className="nav-link" to="eligibility">
                Eligibility
              </NavLink>
              <NavLink className="nav-link" to="pattern">
                Exam Pattern
              </NavLink>
              <NavLink className="nav-link" to="admit-card">
                Admit Card
              </NavLink>
              <NavLink className="nav-link" to="cutoff">
                Cutoff
              </NavLink>
              <NavLink className="nav-link" to="result">
                Result
              </NavLink>
              {/* <NavLink className="nav-link" to="predictor">
                Exam Predictor
              </NavLink>
              <NavLink className="nav-link" to="participating-college">
                Participate College
              </NavLink> */}
            </nav>

            <Outlet />
          {/* </div> */}
          {/* <div className="col-md-4 mt-5 pt-2">
            <div className="campus_view mt-2 p-3">
              <span>Campus View</span>
              <span className="view_all" style={{ float: "right" }}>
                View All
              </span>
              <div
                id="carouselExampleControls"
                className="carousel slide pt-3"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={require("../../images/colleges/college (1).png")}
                      className="d-block w-100"
                      alt="img1"
                      height="280px"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={require("../../images/colleges/college (2).png")}
                      className="d-block w-100"
                      alt="img2"
                      height="280px"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={require("../../images/colleges/college (3).png")}
                      className="d-block w-100"
                      alt="img3"
                      height="280px"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="new_updates mt-2 p-3">
              <p className="mb-0">New Updates</p>
            </div>
          </div> */}
        {/* </div> */}
      </div>
      <div className="container">
        <FeaturedColleges />
      </div>
    </>
  );
}

export default ExamPage;

import "./HeroSection.css";
import React, { memo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <>
      <Container className="hero_section py-3">
        <Row className="my-md-5 mt-5">
          <Col md={6} className="pt-lg-5">
            <p className="fw-bold">Trusted by more than 2,00,000 users</p>
            <h1 className="text-theme">
              Discover Colleges and <br />
              Your Ideal Career
            </h1>
            <p className="py-3 w-80">
              Unlock Your Future: Explore Colleges, Ignite Your Passion, and
              Forge Your Ideal Career Path. Your Journey Starts Here.
            </p>
            <Link
              to="/all-college"
              className="py-2 px-5 bg-theme text-white border rounded-2"
            >
              Explore Now
            </Link>
            <div className="mt-lg-5 mt-3 d-flex">
              <div className="hero_card d-flex overflow-hidden me-2 me-md-3 px-3 pt-1 pb-2 rounded-1">
                <div className="py-2">
                  <i
                    className="fa-solid fa-building-columns"
                    style={{ color: "#fff", fontSize: "25px" }}
                  ></i>
                </div>
                <div
                  className="section_text ps-2 pt-2 text-light"
                  style={{ fontSize: "11px" }}
                >
                  <span>
                    10,000+
                    <br />
                    College's
                  </span>
                </div>
              </div>
              <div className="hero_card d-flex overflow-hidden me-2 me-md-3 px-3 pt-1 pb-2 rounded-1">
                <div className="py-2">                 
                  <i
                    className="fa-solid fa-graduation-cap"
                    style={{ color: "#fff", fontSize: "25px" }}
                  ></i>
                </div>
                <div
                  className="section_text ps-2 pt-2 text-light"
                  style={{ fontSize: "11px" }}
                >
                  <span>
                    100+
                    <br />
                    Courses's
                  </span>
                </div>
              </div>
              <div className="hero_card d-flex overflow-hidden me-2 me-md-3 px-3 pt-1 pb-2 rounded-1">
                <div className="py-2">
                  <i
                    className="fa-solid fa-user-graduate"
                    style={{ color: "#fff", fontSize: "25px" }}
                  ></i>
                </div>
                <div
                  className="section_text ps-2 pt-2 text-light"
                  style={{ fontSize: "11px" }}
                >
                  <span>
                    30,000+
                    <br />
                    Students's
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="mt-2 d-none d-md-block">
            <Row>
              <Col md={6} className="hero_image_text">
                <p className="pOne rounded-pill px-5 py-2 py-md-3">
                  30,000+ One
                </p>
                <div className="divOne">
                  <img
                    src={require("../../../images/header-img-1.png")}
                    alt="hero-1"
                    height="100%"
                    width="100%"
                  />
                </div>
              </Col>
              <Col md={6} className="hero_image_text">
                <p className="pTwo rounded-pill px-5 py-2 py-md-3">
                  30,000+ Two
                </p>
                <div className="divTwo">
                  <img
                    src={require("../../../images/header-img-2.png")}
                    alt="hero-1"
                    height="100%"
                    width="100%"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default memo(HeroSection);
